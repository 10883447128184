import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Header } from './header';
import experience from './resume/experience.json';
import education from './resume/education.json';
import skills from './resume/skills.json';
import certifications from './resume/certifications.json';
import projects from './resume/projects.json';

const Experience = () => {
  return (
    <div>
      {experience.length !== 0 && <div className="text-sm text-left font-bold text-blue-500 mt-10">EXPERIENCE</div>}
      {experience.map((job) => (
        <div key={job.companyName}>
          <div className="text-sm text-left text-black mt-4"><b>{job.companyName}</b>, {job.location} &#8212; {job.jobTitle}</div>
          <div className="text-xs text-left text-black">{job.startDate} - {job.endDate}</div>
          <ul className="list-disc ml-4">
            {job.description.map((line) => (
              <li key={line} className="text-sm text-left text-black">{line}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

const Education = () => {
  return (
    <div>
      {education.length !== 0 && <div className="text-sm text-left font-bold text-blue-500 mt-10">EDUCATION</div>}
      {education.map((school) => (
        <div key={school.schoolName}>
          <div className="text-sm text-left text-black mt-4"><b>{school.schoolName}</b>, {school.location} &#8212; {school.degree}</div>
          <div className="text-xs text-left text-black">{school.startDate} - {school.endDate}</div>
          <div className="text-sm text-left text-black">{school.description}</div>
        </div>
      ))}
    </div>
  );
}

const Skills = () => {
  return (
    <div>
      {skills.length !== 0 && <div className="text-sm text-left font-bold text-blue-500 mt-10">SKILLS</div>}
      {skills.map((skill) => (
        <div key={skill}>
          <div className="text-sm text-left text-black mt-2">{skill}</div>
        </div>
      ))}
    </div>
  );
}

const Certifications = () => {
  return (
    <div>
      {certifications.length !== 0 && <div className="text-sm text-left font-bold text-blue-500 mt-10">CERTIFICATIONS</div>}
      {certifications.map((certification) => (
        <div key={certification.name}>
          <div className="text-sm text-left text-gray-600 mt-2"><b>{certification.name} &#8212; {certification.date}</b></div>
          <div className="text-sm text-left text-black">{certification.description}</div>
        </div>
      ))}
    </div>
  );
}

const Projects = () => {
  return (
    <div>
      {projects.length !== 0 && <div className="text-sm text-left font-bold text-blue-500 mt-10">PROJECTS</div>}
      {projects.map((project) => (
        <div key={project.name}>
          <div className="text-sm text-left text-gray-600 mt-2"><b>{project.name} &#8212; {project.shortDescription} &#40;{project.role}&#41;:</b></div>
          <div className="text-sm text-left text-black">{project.description}</div>
          <div className="text-sm text-left text-black">{project.repo}</div>
        </div>
      ))}
    </div>
  );
}

export const Resume = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="h-screen flex flex-col">
      <Header
        placeholder={
          <div className="btn btn-ghost tooltip tooltip-bottom content-center" data-tip="Print résumé" onClick={handlePrint}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
            </svg>
          </div>
        }
        currView={"resume"}
      />
      <div className="overflow-y-scroll py-4">
        <div ref={componentRef} className="w-[850px] flex-grow h-[1100px] bg-white mx-auto shadow-lg">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col w-2/3">
              <div className="w-3/4 mx-auto">
                <div className="text-5xl font-bold text-left text-black mt-10 font-serif">Ethan Michaelis</div>
                <div className="text-sm text-left text-black mt-2 leading-tight">I am a computer science graduate from BYU with experience in Linux system administration and site reliability engineering.</div>
                <Experience />
                <Education />
              </div>
            </div>
            <div className="flex flex-col w-1/3">
              <div className="w-3/4">
                <div className="text-sm text-left text-black mt-10 leading-tight">ethanmichaelis.com</div>
                <div className="text-sm text-left text-black font-bold leading-tight">(385) 321-0020</div>
                <div className="text-sm text-left text-black font-bold leading-tight">ethanmchls@gmail.com</div>
                <div className="text-sm text-left text-black font-bold leading-tight">linkedin.com/in/ethanmchls</div>
                <Skills />
                <Certifications />
                <Projects />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
