import { Header } from './header';
import MainPic from './media/main-red.jpg';
import OITLogo from './media/oit.png';
import InstructureLogo from './media/instructure.png';
import GradPic from './media/grad.jpeg';
import StreetCamPic from './media/street-cam.jpg';
import JeepPic from './media/jeep.jpeg';
import BandPic from './media/band.JPG';
import WatchPic from './media/watch-movement.jpeg';
import WalkingPic from './media/walking.jpeg';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef } from "react";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: .8 }
};

const Box = ({ body }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  
  return (
    <motion.div ref={ref} variants={boxVariant} initial="hidden" animate={control} className={`mx-auto flex flex-col snap-center snap-both h-[100%] mb-1`}>
      {body}
    </motion.div>
  );
}

export const Home = () => {
  const refEducation = useRef();
  const refCareer = useRef();
  const refHobbies = useRef();
  const refMusic = useRef();
  const refWatch = useRef();
  // const handleEducationClick = () => {
  //   refEducation.current.scrollIntoView();
  // }
  const handleCareerClick = () => {
    refCareer.current?.scrollIntoView();
  }
  const handleHobbiesClick = () => {
    refHobbies.current?.scrollIntoView();
  }
  const handleMusicClick = () => {
    refMusic.current?.scrollIntoView();
  }
  // const handleWatchClick = () => {
  //   refWatch.current?.scrollIntoView();
  // }
  return (
    <div className="h-screen flex flex-col">
      <Header currView={"home"} />
      <div className="overflow-y-scroll snap-y snap-mandatory">
        <div className={`mx-auto flex flex-col relative w-full snap-center snap-both h-[100%] mb-1`}>
          <img src={MainPic} alt="Ethan Michaelis" className="object-cover w-full h-full" />
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 xl:-translate-x-0 lg:-translate-x-0 md:-translate-x-0 sm:-translate-x-0 -translate-x-1/2">
            <h1 className="text-6xl text-center text-neutral my-4">Ethan<br />Michaelis</h1>
            <h2 className="text-2xl text-center text-neutral my-4 hover:link" onClick={handleCareerClick}>Computer Science</h2>
            <h2 className="text-2xl text-center text-neutral my-4 hover:link" onClick={handleHobbiesClick}>Photography</h2>
            <h2 className="text-2xl text-center text-neutral my-4 hover:link" onClick={handleMusicClick}>Music</h2>
          </div>
          <div className="absolute top-[90%] left-1/2 transform -translate-x-1/2">
            <h2 className="text-2xl text-center text-neutral">More about me</h2>
            <h2 className="text-2xl text-center text-neutral mb-4 animate-bounce">⌄</h2>
          </div>
        </div>
        <Box body={
          <div ref={refEducation} className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg text-center text-neutral-content mt-4 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-3/4 mx-auto space-y-8 mb-auto">
            <h2 className="text-3xl font-bold mt-12">Education</h2>
            <p>
              I am a <b>computer science</b> graduate from BYU. My educational experience includes software engineering, web development, and app development. I have also taken courses in computational theory, software design, systems programming, and cybersecurity.
            </p>
            <img src={GradPic} alt="Graduation" className="w-1/2 mx-auto aspect-[7/3] object-cover" />
          </div>
        } />
        <Box body={
          <div ref={refCareer} className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg text-center text-neutral-content mt-4 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-3/4 mx-auto space-y-2 sm:space-y-2 md:space-y-8 lg:space-y-8 xl:space-y-8 2xl:space-y-8 mb-auto">
            <h2 className="text-3xl font-bold mt-12">Career</h2>
            <p>
              My professional experience includes <b>Linux platform engineering</b> and <b>site reliability engineering.</b>
            </p>
            <p>
              As a Linux platform engineer at BYU's Office of I.T., I learned good system administration practices and worked with various tools including forward and reverse proxies, LVM, Firewalld, LDAP, and Ansible. That experience prepared me to take and pass the RedHat Certified System Administrator exam &#40;RHCSA&#41;, and it opened my eyes to a half of the tech world that I didn't previously understand.
            </p>
            <p>
              As a site reliability engineer at Instructure, I have also worked with AWS and various technologies including Docker, Kubernetes, Jenkins, and Terraform.
            </p>
            <div className="flex flex-row justify-center space-x-8">
              <a href="https://oit.byu.edu/" target="_blank" rel="noreferrer"><img src={OITLogo} alt="OIT" className="w-20" /></a>
              <a href="https://www.instructure.com/" target="_blank" rel="noreferrer"><img src={InstructureLogo} alt="Instructure" className="w-20" /></a>
            </div>
          </div>
        } />
        <Box body={
          <div ref={refHobbies} className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg text-center text-neutral-content mt-4 w-[90%] sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 mx-auto space-y-2 sm:space-y-2 md:space-y-8 lg:space-y-8 xl:space-y-8 2xl:space-y-8 mb-auto">
            <h2 className="text-3xl font-bold mt-0 sm:mt-4 md:mt-12 lg:mt-12 xl:mt-12 2xl:mt-12">Hobbies</h2>
            <p>
              Many hobbies dominate my free time, including photography, videography, golf, music, and watch collecting.
            </p>
            <h2 className="text-2xl font-bold mt-0 sm:mt-0 md:mt-4 lg:mt-4 xl:mt-4 2xl:mt-4">Photography & Videography</h2>
            <div className="flex sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row flex-col items-center">
              <img src={StreetCamPic} alt="Street Cam" className="w-1/3 sm:w-1/3 md:w-1/3 lg:w-1/2 xl:w-1/3 2xl:w-1/3 aspect-square sm:aspect-[5/7] md:aspect-[5/7] lg:aspect-[5/7] xl:aspect-[5/7] 2xl:aspect-[5/7] object-cover m-2 sm:m-2 md:m-4 lg:m-4 xl:m-4 2xl:m-4" />
              <div className="flex flex-col items-center">
                <p>
                  I like to take pictures of landscapes, architecture, and people. My friends and I enjoy making short videos including sketch comedy and cinematic shorts. This hobby began while I was in high school, and I've competed at a state level in various categories. I have also worked on a couple projects for local businesses.
                </p>
                <img src={JeepPic} alt="Jeep" className="mx-auto w-1/2 sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 2xl:w-2/3 aspect-[16/9] sm:aspect-[7/5] md:aspect-[7/5] lg:aspect-[7/5] xl:aspect-[7/5] 2xl:aspect-[7/5] object-cover m-2 sm:m-2 md:m-4 lg:m-4 xl:m-4 2xl:m-4" />
              </div>
            </div>
          </div>
        } />
        <Box body={
          <div ref={refMusic} className="text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg text-center text-neutral-content mt-4 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-3/4 mx-auto space-y-8 mb-auto">
            <h2 className="text-2xl font-bold mt-12">Music</h2>
            <div className="flex sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col items-center">
              <p className="sm:text-center md:text-center lg:text-right xl:text-right 2xl:text-right text-center">
                Music is an incredibly important part of my life. I started learning piano when I was 8 years old, but my real passion has been guitar. This passion includes playing, writing, and recording music. I have competed in talent shows and battle of the bands, and my band has played live several times.
              </p>
              <img src={BandPic} alt="Guitar" href="https://www.youtube.com/@justiceforheroesofficial3925/videos" target="_blank" rel="noreferrer" className="aspect-[7/5] object-cover sm:w-full md:w-full lg:w-1/2 xl:w-1/3 2xl:w-1/3 w-full m-4" />
            </div>
          </div>
        } />
        <Box body={
          <div ref={refWatch} className="text-xs sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg text-center text-neutral-content mt-4 w-[90%] sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 2xl:w-1/2 mx-auto space-y-2 sm:space-y-2 md:space-y-8 lg:space-y-8 xl:space-y-8 2xl:space-y-8 mb-auto">
            <h2 className="text-2xl font-bold mt-4 sm:mt-4 md:mt-12 lg:mt-12 xl:mt-12 2xl:mt-12">Watch Collecting</h2>
            <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row">
              <div className="flex flex-col">
                <div className="flex sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-col items-center">
                  <img src={WatchPic} alt="Watch Movement" className="aspect-[5/2] sm:aspect-[4/3] md:aspect-[4/3] lg:aspect-[4/3]xl xl:aspect-[4/3] 2xl:aspect-[4/3] object-cover w-1/2 sm:w-2/3 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/3 m-4" />
                  <p className="sm:text-center md:text-center lg:text-left xl:text-left 2xl:text-left text-center">
                    Watch collecting is my most unusual hobby. Few understand the appeal of spending money on mechanical watches when our phones tell the time better. I find the craftsmanship, history, art, and engineering of mechanical watches fascinating. And few things allow me to express my personality and style like a watch does.
                  </p>
                </div>
                <p className="m-4">
                  In a world where everything else in my life is dictated by my phone, it's nice to have something that doesn't rely on the internet, electricity, or satellites to guide me through my day.
                </p>
              </div>
              <img src={WalkingPic} alt="Walking" className="w-1/3 sm:w-full md:w-full lg:w-1/5 xl:w-1/4 2xl:w-1/4 aspect-[3/4] sm:aspect-[9/16] md:aspect-[9/16] lg:aspect-[9/16] xl:aspect-[9/16] 2xl:aspect-[9/16] object-cover m-0 sm:m-0 md:m-4 lg:m-4 xl:m-4 2xl:m-4 mx-auto" />
            </div>
          </div>
        } />
      </div>
    </div>
  );
}