import { Header } from './header';
import { useNavigate } from 'react-router-dom';
import PhotoCardPic from './media/sensor-macro.JPG';
import SoftwareCardPic from './media/command-prompt.jpg';

export const Portfolio = () => {
  const navigate = useNavigate();

  const handlePhotographyClick = () => {
    navigate('/portfolio/photo');
  }

  const handleSoftwareClick = () => {
    navigate('/portfolio/software');
  }

  return (
    <div className="h-screen flex flex-col">
      <Header currView={"portfolio"} />
      <div className="overflow-y-scroll">
        <div className="xl:w-1/2 lg:w-1/2 md:w-2/3 sm:w-2/3 w-4/5 flex flex-col my-4 mx-auto">
          <div className="grid grid-flow-row text-neutral grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 justify-between gap-4">
            <div className="card w-auto h-full bg-secondary shadow-md rounded-md hover:shadow-lg hover:scale-[102%]" onClick={handlePhotographyClick}>
              <figure><img src={PhotoCardPic} alt="Photography" /></figure>
              <div className="card-body">
                <h2 className="card-title text-secondary-content">Photography</h2>
                <p>Explore some of my favorite photos I've taken over the years</p>
              </div>
            </div>
            <div className="card w-auto h-full bg-secondary shadow-md rounded-md hover:shadow-lg hover:scale-[102%]" onClick={handleSoftwareClick}>
              <figure><img src={SoftwareCardPic} alt="Software" className="aspect-video" /></figure>
              <div className="card-body">
                <h2 className="card-title text-secondary-content">Software</h2>
                <p>Take a look at a few of the programs I've worked on</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}