import Profile from './media/profile.jpeg';
import { useNavigate } from 'react-router-dom';

export const Header = ({ placeholder, currView }) => {
  const navigate = useNavigate();

  const handlePortfolioClick = () => {
    navigate('/portfolio');
  }

  const handleResumeClick = () => {
    navigate('/resume');
  }

  const handleHomeClick = () => {
    navigate('/');
  }

  return (
    <div className="navbar bg-neutral sticky top-0 z-10 h-16">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </div>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-auto">
            <li><a href="#/portfolio">Portfolio</a></li>
            <li><a href="#/resume">Résumé</a></li>
            <li>{placeholder}</li>
          </ul>
        </div>
        <div className={`btn btn-ghost text-xl ${currView === "home" ? "text-primary" : "text-neutral-content"}`} onClick={handleHomeClick}>Ethan Michaelis</div>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li><div className={`btn btn-ghost text-${currView === "portfolio" ? "primary" : "neutral-content"}`} onClick={handlePortfolioClick}>Portfolio</div></li>
          <li><div className={`btn btn-ghost text-${currView === "resume" ? "primary" : "neutral-content"}`} onClick={handleResumeClick}>Résumé</div></li>
          <li>{placeholder}</li>
        </ul>
      </div>
      <div className="navbar-end">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img alt="Profile" src={Profile} />
            </div>
          </div>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-neutral rounded-box w-auto">
            <li>
              <button className="flex flex-row btn btn-ghost p-0 w-32 justify-start pl-4" onClick={() => {window.open('https://www.linkedin.com/in/ethanmchls/')}}>
                Linkedin
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                </svg>
              </button>
              <button className="flex flex-row btn btn-ghost p-0 w-32 justify-start pl-4" onClick={() => {window.open('https://github.com/ethanmchls')}}>
                Github
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}