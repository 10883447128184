import { Header } from './header';
import projects from './project_data/projects.json';
const images = require.context('./media/software_portfolio', true);

const ProjectCard = ({ project }) => {
  console.log(images.keys().indexOf(`./${project.mainImage.src}`));
  return (
    <div className="card lg:card-side xl:card-side 2xl:card-side bg-base-100 shadow-xl hover:scale-[102%] hover:shadow-2xl">
      <figure>
        <img
          src={images(`./${project.mainImage.src}`)}
          alt={project.mainImage.alt}
          className="max-h-96" />
      </figure>
      <div className="card-body bg-base-300 rounded-r-box">
        <h2 className="card-title text-primary">{project.title}</h2>
        <p className="text-neutral-content">{project.description}</p>
        {/* <div className="card-actions justify-end">
          <button className="btn btn-primary">Listen</button>
        </div> */}
      </div>
    </div>
  )
}

export const SoftwarePortfolio = () => {
  return (
    <div className="h-screen flex flex-col">
      <Header currView={"portfolio"} />
      <div className="overflow-y-scroll py-4">
        <div className="xl:w-2/3 lg:w-2/3 md:w-2/3 sm:w-2/3 w-4/5 mx-auto flex flex-col">
          <div className="grid grid-flow-row text-neutral grid-cols-1 justify-between gap-4">
            {projects.map((project) => (
              <ProjectCard project={project} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}