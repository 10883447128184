// import logo from './logo.svg';
import './App.css';
import { Home } from './Home.js';
import { Portfolio } from './Portfolio.js';
import { PhotoPortfolio } from './Photo.js';
import { SoftwarePortfolio } from './Software.js';
import { Resume } from './Resume.js';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/portfolio/photo" element={<PhotoPortfolio />} />
      <Route path="/portfolio/software" element={<SoftwarePortfolio />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
